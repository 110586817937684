import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Logo from './components/Header/Logo';
import ThemeSwitcher from './components/Header/ThemeSwitcher';

import MainPage from './pages/mainpage';
import RoutesCentral from './pages/routesCentral';
import nationRoutes from './pages/nationPages';
import eventRoutes from './pages/eventPages';

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Link style={{ textDecoration: 'none' }} to="/"><Logo /></Link>

          <div style={{textAlign:'center',flexDirection:'column'}}>
            <span style={{ fontSize: 'large', textAlign: 'center' }}>Versão 0.1.2 <br/> (Ainda em desenvolvimento!)<br/></span>
            <Link style={{textDecoration:'none', fontSize:'large'}} to="/changelog">Veja a Changelog</Link>
            </div>

          <ThemeSwitcher />

        </header>
        
        <div id="PageBody">
          <RoutesCentral />
        </div>
      </Router>
    </div>
  );
}

export default App;
