import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function Quaqasa() {
    return (
        <div>
            <PageTitle title="Quaqasa" />

            <InfoboxNation
                nome="Quaqasa"
                bandeiraSrc={images.quaqasaFlag}
                nomeoficial="Império das Ilhas Quaqasa"
                capital="Quaqasia"
                governo="Monarquia Absolutista Teocrática"
                religiao="Taoismo"
                governante="-"
                populacao="9 Milhões"
                moeda="Kimo"
                idh="-"
                idioma="Português, Mandarim e outros">
            </InfoboxNation>
        </div>
    );
}

export default Quaqasa;