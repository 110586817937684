import React from "react";
import { ReactComponent as PindoramaMap } from '../../assets/svg/Pindorama.svg';
import './MapComponent.css';
import { useNavigate } from "react-router-dom";

function SvgMap() {
    const navigate = useNavigate();

    const handleNavigation = (event) => {
        const path = event.target.dataset.path;
        if (path) {
            event.preventDefault();
            navigate(path);
        }
    };

    return (
        <div className="SvgMap" onClick={handleNavigation}>
            <div>
                <PindoramaMap className="SvgMapFile"/>
            </div>
        </div>


    );
}

export default SvgMap;
