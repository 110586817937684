import PageTitle from "../../components/ArticleTitles/PageTitle"

function Changelog(){
    return(
        <div>
            <PageTitle title="Changelog"/>
            <div>
                <PageTitle title="0.1.1" level={2}/>
                <ul>
                    <li>Enciclopædia Pindorâmica Lançada</li>
                    <li>Páginas de Minuano, Montes Claros, Vasterras e Vazios Polares adicionadas</li>
                    <li>Modelo base de InfoBox para nações, eventos e pessoas criado</li>
                    <li>Temas implementados</li>
                    <li>Rotas implementadas</li>
                </ul>
                <PageTitle title="0.1.2" level={2}/>
                <ul>
                    <li>Changelog adicionada</li>
                    <li>Páginas de Nova Harmonia, Potiguaro e Nova Bangú com mais conteúdo</li>
                    <li>Ajustes menores no espaçamento</li>
                    <li>Página de Baependy escrita (obrigado Empire!)</li>
                    <li>Modelo de citações criado</li>
                </ul>
            </div>
        </div>
    );
}

export default Changelog