import SvgTooltipMap from '../components/SvgMap/SvgTooltipMap';
import './mainpage.css';

import ImageGallery from '../components/ImageGallery/ImageGallery';
import TextBox from '../components/TextBox/TextBox';
import { images } from '../components/ImagesSrc';
import Tooltip from '../components/SvgMap/Tooltip';

function MainPage() {
    return (
        <div className="MainPage">
            <h1 className="MainTitle">Enciclopædia Pindorama</h1>
            <h3 className="MainSubtitle">A Enciclopédia Definitiva de Pindorama</h3>

            <div className="MainPageContent">
                <div className="TextBoxes">
                    <TextBox
                        title="O que é o Projeto Pindorama?"
                        text="O Projeto Pindorama se trata de uma criação de mundo (World Building) no
                        planeta Pindorama e suas nações, com cada nação sendo a criação de um
                        membro do projeto."
                    />

                    <TextBox
                        title="O que é o Planeta Pindorama?"
                        text="Pindorama é um planeta além do Sistema Solar que foi exclusivamente
                        colonizado por brasileiros nos anos de 1995 a 2001. Sua geologia é
                        similar a da Terra, porém Pindorama é ligeiramente menor."
                    />

                    <TextBox
                        title="O que é a Enciclopædia Pindorâmica?"
                        text="A Enciclopædia Pindorâmica é a principal fonte de informações do
                        Projeto Pindorama, toda e qualquer informação relacionada ao Projeto Pindorama é
                        bem vinda."
                    />
                </div>

                <div className="MainPageMap">
                    <SvgTooltipMap/>
                </div>

            </div>



        </div>
    );
}

export default MainPage;