import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function NovaBangu() {
    return (
        <div>
            <PageTitle title="-" />

            <InfoboxNation
                nome="Nova Bangú"
                bandeiraSrc={images.novabanguFlag}
                nomeoficial="-"
                capital="Morro da Esquideda"
                governo="Cleptocracia"
                religiao="Nenhuma"
                governante="-"
                populacao="-"
                moeda="-"
                idh="-"
                idioma="Português">
            </InfoboxNation>
        </div>
    );
}

export default NovaBangu;