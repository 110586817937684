import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function Bradualia() {
    return (
        <div>
            <PageTitle title="-" />

            <InfoboxNation
                nome="-"
                bandeiraSrc={images.bradualiaFlag}
                nomeoficial="-"
                capital="-"
                governo="-"
                religiao="-"
                governante="-"
                populacao="-"
                moeda="-"
                idh="-"
                idioma="Português">
            </InfoboxNation>
        </div>
    );
}

export default Bradualia;