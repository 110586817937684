import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function NovaCambria() {
    return (
        <div>
            <PageTitle title="Nova Câmbria"/>

            <InfoboxNation
                nome="Nova Câmbria"
                bandeiraSrc={images.novacambriaFlag}
                nomeoficial="Nova Câmbria"
                capital="-"
                governo="-"
                religiao="-"
                governante="-"
                populacao="-"
                moeda="-"
                idh="-"
                idioma="Português">
            </InfoboxNation>
        </div>
    );
}

export default NovaCambria;