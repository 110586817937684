import React, { useState } from 'react';
import SvgMap from './SvgMap';
import Tooltip from './Tooltip';

function SvgTooltipMap() {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [hoveredNation, setHoveredNation] = useState('');

    const handleMouseMove = (event) => {
        setPosition({
            x: event.clientX,
            y: event.clientY
        });
    };

    const handleNationHover = (nationName) => {
        setHoveredNation(nationName);
    };

    return (
        <div onMouseMove={handleMouseMove} style={{ position: 'relative' }}>
            <SvgMap onNationHover={handleNationHover} />

            {hoveredNation && (
                <Tooltip position={position} nome={hoveredNation} />
            )}
        </div>
    );
}

export default SvgTooltipMap;
