import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';
import Quote from '../../components/TextBox/Quote';

function Baependy() {
  return (
    <div>
      <PageTitle title="Baependy" />

      <InfoboxNation
        nome="Baependy"
        bandeiraSrc={images.baependyFlag}
        nomeoficial="Reino de Baependy"
        capital="São Lucas"
        governo="Monarquia"
        religiao="Catolicismo"
        governante="João I Pinheiro Lobo"
        populacao="42 Milhões"
        moeda="Reyss Baepenysys"
        idh="0,780"
        idioma="Alto Baependiano e Portuneiro">
      </InfoboxNation>

      <PageTitle title="História" level={2} />

      <PageTitle title="Primeiros Anos" level={3} />
      <p>
        Com a Chegada da Tecnologia dos Portais na Terra
        Os Brasileiros viram uma ótima oportunidade para colonizar
        um planeta e enriquecer a nação, durante o Governo do então presidente ‘’Fernando Henrique Cardoso’’  ele incentivou os estados
        a colonizarem várias partes do planeta que estava sendo descoberto seu nome foi dado como ‘’Pindorama’’ , um dos principais estados
        a começarem a colonizar pindorama foi Minas Gerais com cidades como Baependi e Montes Claros, Baependy sendo a Primeira Cidade do Sul
        de Minas Gerais a colonizar o planeta de Pindorama com o apoio do Prefeito da cidade
      </p>

      <p>
        O Prefeito da Cidade resolveu apoiar um grupo de colonizadores Que estava ganhando muita popularidade em Baependi, os chamados ‘’Nova Esperança
        Brasileira’’-NEB, Um Grupo de Jovens Adultos Monarquistas baependianos que não aceitavam a Derrota do Plebiscito da Monarquia do Brasil em 1993,
        eles foram convocados pelo Governo de Baependi para colonizarem o planeta novo o que não só atraiu baependianos como também pessoas ao redor de
        outras cidades como São Lourenço,Caxambu,Varginha,São João del Rei,Passa Quatro e muitos outros, um grupo de 50 famílias foram convocadas para
        colonizarem e catalogarem o local, a primeira vista a colônia da Nova Esperança Brasileira se viu num bioma de Savana sendo muito quente para
        os padrões dos brasileiros Entretando como o planeta era desconhecido ainda eles resolveram fazer a colônia ali mesmo próximo da praia.
      </p>

      <p>
        Mas foi durante o Governo de ‘’Fernando Souza de Marlston’’ (1999-2003) que ele deu uma impulsionada na Colonia Enviando diversos suprimentos
        para lá que não conseguia se sustentar sozinha sem a ajuda do governo brasileiro no ainda governo do Fernando Henrique Cardoso, enviando comida,
        armas para defesa pessoal contra os animais hostis da savana, barcos para os pescadores amadores que conseguiam sustentar a colônia por algum
        certo tempo, e muito em breve o prefeito de Baependi pretendia enviar até carros para colônia afim de construir estradas no futuro, o que acabou
        não indo pra frente devido a outro acontecimento.
      </p>

      <PageTitle title="Progressão da Colônia" level={4} />

      <p>
        Durante a colonização do território local o governo da Cidade de Baependi, Tanto o Prefeito Frederico de Fonseca Cardoso e Fernando Souza de Marlston
        durante 1995 e 2001, A Colônia da Nova Esperança Brasileira se via em Dificuldades Para criar assentamentos no local devido a baixa quantidade de árvores
        devido a região que o portal levou os baependianos que por ser uma Região de Savana era difícil de criar as primeiras casas E muitos deles tinham que
        importar concreto do brasil sendo caro Pra colonizar mas era necessário pois era a única coisa que o governo podia oferecer para construção de casas,
        no começo as casas seriam pequenas de apenas 1 andar e poucos cômodos pois a colônia ainda era muito pequena e estava crescendo de pouco em pouco e não
        podiam simplesmente pegar tudo que tinham em suas casas na Terra. e durante os anos de 1995 a 2000, 1 ano antes do portal fechar. A Colônia da Nova Esperança
        Brasileira fez a uma das primeiras construções na nova colônia, uma Igreja foi construída como forma de Homenagear a Cidade e também como os portugueses
        fizeram quando vieram colonizar o brasil em 1500.
      </p>

      <p>
        A Progressão da Colônia ia muito bem com o Prefeito Fernando fazendo pesados investimentos sobre a colônia como por exemplo construir as primeiras casas mesmo
        que seja pequenas mas isso era apenas temporário, já que durante o seu governo a colônia ainda não tinha infraestrutura se limitando a apenas estradas feitas
        de terra. nos anos 2000 a Colônia de Baependy já abrigava cerca de 2500 pessoas muitos desses vindos de outras cidades mas a maioria sendo nativa de Baependy.
        Esse boom populacional que se deu na colônia foi a descoberta de ouro em algumas regiões da colônia. Além de ouro também foram encontrados outros minérios como
        prata, cobre, ferro e nióbio. Essa descoberta fez com que o prefeito de baependy fizesse campanha para incentivar as pessoas da cidade e de cidades vizinhas a
        irem até a colônia interplanetária para trazerem seus recursos e fazer com que o brasil crescesse e prosperasse, mas o destino tinha outras prioridades e uma
        dessas prioridades fizeram com que o destino fosse terrível para os Baependyanos.
      </p>

      <PageTitle title="Fechamento dos Portais" level={3} />

      <p>
        Após o portal se fechar em 2001, a colônia se encontrava em uma situação terrível e avassaladora, os portais sumiram de repente e a colônia se encontrava em
        fragilidade com muitas pessoas indo a beira da loucura tentando encontrar formas de fazer os portais aparecerem de novo para voltarem pra terra, mas eles nunca
        mais se abririam. O Fundador da Colônia vendo que a situação era terrível para a Colônia ele reuniu os seus melhores homens e convocou todos eles em uma reunião
        na Igreja de Jesus Cristo de Baepensysy e lá o Lucas Pinheiro (Fundador da Colônia) se proclamou o Líder de Baependy. Afim de conter as loucuras e revoltas da
        População de Baependy, ele reuniu toda a população da colônia em frente a igreja, dizendo para eles não continuarem com as atitudes que eles estavam fazendo pois
        aquilo não ia trazer os portais de volta e que só ia piorar a situação, ele disse também para nunca entrarem em pânico pois eles nunca estariam sozinhos, pois
        cristo sempre estaria lá por eles assim como ele se sacrificou por nós para salvar nossas almas de pecados terríveis. Desde esse dia as revoltas e loucuras das
        pessoas pararam mesmo em que menor escala. Lucas pinheiro como líder de baependy não sabia como a colônia ia ser no futuro e temendo que o nome do antigo brasil
        fosse perdido por eles e também de sua cidade natal na terra resolveu anotar tudo em um diário para não esquecerem de nada e resolveu deixar ele nas mãos dos padres
        da única igreja da colônia, o padre ‘’Antonio Humbertus de Israel’’ se encarregaria de proteger o povo das paranoias que os consumiam a cada dia. Lucas Pinheiro
        Governou baependy até os seus 67 anos no Ano 29.
      </p>

      <p>
        Após a morte do Líder da Colônia seu sucessor seria o Tiago Almeira Pinheiro de Maria, porem antes que ele pudesse liderar a colônia no ano 30 ele foi traído pelos
        militares que eram encarregados de proteger a colônia dos perigos que assolavam a Nova Esperança Brasileira. Ele foi exilado para a península acima da colônia que
        naquele ano estava se expandindo rumo ao norte, Tiago e sua família foram exilados na então região denominada de ‘’Ararynya’hs’’ que nos dias de hoje é denominada de
        ‘’Perdizes’’.
      </p>

      <p>
        Tiago tentou negociar com os Militares dizendo que se ele os liderasse ele poderia oferecer conforto a eles e ao povo. Porém ele foi ignorado e foi exilado mesmo assim
        e no seu exilio ele e sua família testemunhariam o nascimento da nação de biapisia (atualmente Baependy). Porem o que mais entristeceu Tiago foi que o governo era
        liderado apenas por militares ou seja uma ditadura.
      </p>

      <p>
        Tiago morreu no ano 45 aos 75 anos e ele não viveria para ver sua colônia ser grande no futuro outra coisa que ele não sabia, era que sua família iria liderar Biapisia
        no futuro como uma Monarquia.
      </p>

      <p>
        As suas ultimas palavras antes de morrer foram:
      </p>

      <Quote quote="Que Deus proteja essa jovem nação" author="Tiago Pinheiro"></Quote>

      <PageTitle title="O Nascimento da Escravidão" level={3} />

      <p>
        Nos primeiros meses do ano 50 a fome começava a assolar a população de Biapisia com muitos morrendo de desnutrição.
      </p>

      <p>
        O Governo, para tentar evitar que mais pessoas morressem de desnutrição e dentre outras coisas, resolveu tomar uma atitude muito cruel e nefasta. A Escravidão era instituída,
        usando os Neo-Indios e pessoas pobres como forma de aumentar os estoques de alimentos e de produção para a população. Independente de sua cor, eles seriam escravizados se os
        mesmos tivessem uma renda muito baixa.
      </p>

      <p>
        A escravidão marcaria a história de Biapisia, sendo um dos períodos mais terríveis e tristes para aqueles que seriam escravizados. Aqueles que tentassem resistir à
        escravização forçada seriam mortos sem nenhum pudor ou misericórdia.
      </p>

      <p>
        A Igreja não gostou do que fizeram com aqueles que eram escravizados, dizendo que Cristo nunca faria isso com aqueles que ele considerava como seus Filhos. O Governo disse a
        eles para calarem a boca ou até os padres seriam escravizados se tentassem algo contra eles.
      </p>

      <p>
        Naquele momento já estava óbvio que o governo tinha total controle sobre a igreja. Entretanto, eles poderiam continuar com suas missas, desde que não tentassem nada contra o
        governo tirânico do Ditador Carlos Oliveira.
      </p>

      <PageTitle title="A Colonização de Nova Biapisya" level={3} />

      <p>
        No ano 70, no então governo de Ricardo Malassu, ele resolveu enviar uma expedição ao redor do planeta Pindorama para encontrar um lugar mais adequado ao povo de Biapisya,
        já que devido ao calor do país muitos sofriam com a insolação e com as plantas constantemente morrendo devido ao sol quente do bioma de savana. Os primeiros navios a vela
        da nação começavam a ser construídos com a ajuda dos escravizados.
      </p>

      <p>
        Após a construção do navio, ele seria usado com vários outros ao seu redor para explorarem Pindorama. Suas primeiras viagens foram feitas ao redor da Península de Biapisya,
        e foi um sucesso a sua trajetória, tendo seu devido reconhecimento pelas viagens. Foi durante essa primeira viagem que os militares começaram a colonizar o Norte da Península
        e as ilhas acima dela. Em uma dessas viagens, eles começaram a viajar a leste de Biapisya, chegando num outro bioma de savana no continente de Ipiranga.
      </p>

      <p>
        Durante os anos 72 a 115, muitos colonos biapisiensys se aventuraram pela região do Ipiranga, e foi durante a colonização de Nova Biapisya que eles conheceram o Povo
        Harmonense, que se demonstraram ser muito gentis. Eles também explicaram para tomarem cuidado ao sul do continente, pois ali havia uma nação ainda desconhecida para os
        biapisiensys, denominada "Aeterna Brasiliense", que era um local extremamente hostil e perigoso, onde qualquer um que entrasse na região deles seria morto.
      </p>

      <p>
        Após alguns anos, depois de a nação de Biapisya colonizar sua região no Ipiranga e encontrar recursos valiosos, o governo se vê disposto a aumentar seu domínio sobre a região,
        a fim de expandir as fazendas e a extração de minérios para o crescimento econômico da nação, que estava começando a reformar sua administração, principalmente na área militar.
        No então governo do ditador Marcelo de Andradeas, ele se sente obrigado a expandir o domínio de Biapisya sobre os indígenas na região, que estavam causando problemas para os
        agricultores locais próximos de suas fronteiras. Então, ele toma a atitude de iniciar a "Guerra da Reunificação Baependyana".
      </p>

      <ul>
        <li> Biapisya x</li>
        <li>República Livre de Cuyaba’hs</li>
        <li>Tribo Goyytaquenzyas</li>
        <li>Tribo Amelanhyas</li>
        <li>Tribo PavésCumes</li>
        <li>Tribo São Lourencya’hss</li>
        <li>Cidade Livre de São Martynh’uss</li>
        <li>Cidade Estado de Aparecyda’s</li>
        <li>Cidade Estado de Guarulhi’as</li>
        <li>Estado Livre de Caruarhyas</li>
      </ul>

      <p>
        Apesar de Biapisya estar lutando contra nove nações, os exércitos de seus inimigos eram despreparados e muito ineficazes. A batalha contra essas nove nações, a
        maioria composta por tribos indígenas socialmente bem avançadas, porém, seus destinos eram inevitáveis. A primeira batalha foi contra a República Livre de Cuyaba’hs,
        que durante 20 anos de guerra de reunificação deu muita dor de cabeça para Biapisya, pois suas grandes muralhas eram quase impenetráveis.
      </p>

      <p>
        Durante os anos de 99 a 119, a nação de Biapisya havia mostrado seu domínio perante todas as outras regiões. As tribos indígenas foram as primeiras a caírem, uma
        após outra, e na batalha final, a Batalha de Cuyaba’hs, no dia 13/05/119, as tropas de Biapisya adentraram a capital da República de Cuyaba’hs e incendiaram tudo
        ao seu redor até chegarem no castelo e capturarem o presidente do país, encerrando assim a "Guerra de Reunificação Baependyana".
      </p>

      <PageTitle title="Estagnação econômica" level={4} />

      <p>
        Com a vitória de Biapisya sobre todas as regiões ao redor, a nação finalmente se reunificava, anexando todos os nove países. Porém, tudo isso teve um grande custo: a nação de Biapisya contraiu uma enorme dívida econômica, tão grande que não era capaz de pagar o salário de muitos soldados ou de seus superiores. Biapisya tentaria de tudo para cortar gastos, mas isso só enfureceu os soldados, que queriam seus devidos salários. Afinal, a nação de Biapisya ficou 20 anos em guerra, e desses 20 anos, 17 foram apenas para destruir e anexar a República de Cuyaba’hs.
      </p>

      <p>
        O ditador Roberto Fonseca precisava de sugestões por parte de seus generais. Foi aí que o General Auberthus de Labattutes sugeriu o seguinte a Roberto:
      </p>

      <Quote
        quote="Senhor Roberto, por que nós não vendemos aquela nossa colônia de Nova Biapisya para Nova Harmonia? Acredito que eles pagariam bem para ter aquele  território pra eles."
        author="Auberthus" />

      <Quote
        quote=" Bom... se esse for o caso, prepare o meu navio então, vamos negociar com eles sobre essa venda! Eu quero quitar logo essa nossa dívida"
        author="Roberto" />

      <Quote
        quote="Olha, senhor, quando eles se apresentaram para nós lá na colônia a primeira vez, não demonstraram hostilidade. Eles até respeitam as nossas fronteiras e nos 
      retribuíram com a informação sobre uma nação tão hostil quanto nossos inimigos."
        author="Auberthus" />

      <Quote
        quote="Bom... se esse for o caso, prepare o meu navio então, vamos negociar com eles sobre essa venda! Eu quero quitar logo essa nossa dívida. "
        author="Roberto"
      />
    </div>
  );
}

export default Baependy;