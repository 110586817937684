import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';
import InfoboxPerson from '../../components/InfoBox/InfoboxPerson';

function AlbertoIDeMinuano() {
  return (
    <div>
      <PageTitle title="Alberto I de Minuano"/>
        
      <InfoboxPerson
        nascimento="19 de Novembro de 527"
        morte=""
        conjuge="Anna Cristina de Ottoni-Ansbach"
        dinastia="Córdoba-Luna"
        pai="Marcelo IV de Córdoba e Luna"
        mae="Carlota de Dourados"
        religiao="Catolicismo"
      />
    </div>
  );
}

export default AlbertoIDeMinuano;