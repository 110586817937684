import '../page.css';

import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';
import MainArticle from '../../components/ArticleTitles/MainArticle';

import { images } from '../../components/ImagesSrc';

function MontesClaros() {
  return (
    <div>
      <PageTitle title="Montes Claros" />
      <InfoboxNation nome="Montes Claros"
        bandeiraSrc={images.montesclarosFlag}
        nomeoficial="Império de Montes Claros"
        capital="Bonfim"
        governo="Monarquia Absolutista"
        religiao="Ortodoxsimo"
        governante="Imperador Régis V de Ottoni-Ansbach"
        populacao="44,7 Milhões"
        moeda="Arcas Monteclarenses"
        idh="-"
        idioma="Português Monteclarense" />
      <div className='PageContent'>
        O Império de Montes Claros é uma nação que se localiza ao extremo norte do planeta de Pindorama,
        se localizando no continente de Marajó no Mar de Tupã, é tradicionalmente conhecida pelos amplos
        casinos, cordilheiras gigantes e seu clima extremo. tendo similaridades entre Mônaco e a Suíça na terra.
        compondo se pelos principais territórios de Ibituruna, Frei Gaspar e o arquipélago de São Freire.

        <br /> <br />

        A formação do país começou na época da colonização com a vinda de pessoas para a região, apôs o fechamento
        dos portais e o regresso tecnológico. vários donos de terra começaram a se rebelar dando espaço para vários
        conflitos, entretanto depois do Voivodato de Ansbach conseguir unificar o local o Soldado João Miguel Liz
        se casou com uma plebeia de Ottoni, formando assim a casa real de Ottoni-Ansbach. assim seguindo para o
        período do Primeiro Reinado, o qual teve 6 Reis sendo o último deles Regis II, sendo deposto apôs a guerra
        civil. Iniciando o período republicano cujo foi a época que os cassinos começaram a fazer parte da estrutura
        econômica e social do país. com o ex-presidente Agripino de Barros sendo o mentor disto.
      </div>
    </div>
  );
}

export default MontesClaros;