import InfoboxNationHistorical from '../../components/InfoBox/InfoboxNationHistorical';
import InfoboxCountry from '../../components/InfoBox/InfoboxCountry';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';


function Potiguaro() {
    return (
        <div>
            <PageTitle title="Potiguaro" />

            <InfoboxNationHistorical
                nome="Potiguaro"
                periodo="? - 551"
                bandeiraSrc={images.potiguaroFlag}
                nomeoficial="Trinidade de Potiguaro"
                capital="-"
                governo="-"
                religiao="-"
                governante="-"
                populacao="-"
                moeda="-"
                idh="-"
                idioma="Português">
                <InfoboxCountry name="Formosa"/>
            </InfoboxNationHistorical>

            A nação de Trinidade de Potiguaro se localize-se no hemisfério Sul do planeta banhado pelos oceanos Polo e Tupã no 
            continente Pancas formado de uma união de outras 2 nações anteriores sendo república de Potiguar e Trinidade dos 
            Cristores a partir de uma guerra desastrosa pra ambos dos lados que resultou o ramo Manuelista unificar e criar estado 
            Trinidade de Potiguaro

            <br/><br/>

            Possuindo áreas famosas como Ilha de Formosa( Gaspar ), Costa do Marfim ,  Lago Tabajara , altas planícies , Deserto provencial
             e baía da Traição com uma população estimada em 551 de 112 milhões de pessoas, conhecido pelo seu Papa e nação tradicionalista
              católica, economia relativamente boa, Grande investimento e indústrias Químicas pelo país, uma potência regional marítima, 
              histórico de coronelismo de familias no poder e sendo um dos membros da CONMAR tendo grandes  aliados como Baependy,Aratigia 
              e Nova harmonia, porém tendo alta taxa de analfabetismo , povo economicamente fraco, isolacionista diplomaticamente e vulnerável
               a economia internacional e o exército sendo corrupto e em estado deplorável recente e a nação muito instável politicamente 
               e ocasionando várias crises até resultar na morte do papa e causar uma eleição polêmica que resultou na vitória Neo-Igualista 
               e uma guerra civil Entre novo governo e Militares, com a derrota militar acabou qualquer possibilidade de volta do estado Potiguaro 
               original e resultando na criação de Formosa
        </div>
    );
}

export default Potiguaro;