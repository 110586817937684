import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function VaziosPolares() {
  return (
    <div>
      <PageTitle title="Vazios Polares">
        
      </PageTitle>
      <InfoboxNation
          nome="Vazios Polares"
          bandeiraSrc={images.vaziospolaresFlag}
          nomeoficial="República de Vazios Polares"
          capital="Marquese de Agudos"
          governo="República (de jure) Estado Cultista (de facto)"
          religiao="Glacioculto"
          governante="Presidente Márcio Fagundes"
          populacao="-"
          moeda="-"
          idh="-"
          idioma="Português">
        </InfoboxNation>
    </div>
  );
}

export default VaziosPolares;