import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/ImagesSrc';

function NovaHarmonia() {
  return (
    <div>
      <PageTitle title="Nova Harmonia">
        
      </PageTitle>
      <InfoboxNation
          nome="Nova Harmonia"
          bandeiraSrc={images.novaharmoniaFlag}
          nomeoficial="Nova Harmonia"
          capital="Esperança"
          governo="Monaquia Absolutista"
          religiao="Catolicismo"
          governante="Parlamento Harmonense (de jure)"
          populacao="-"
          moeda="-"
          idh="-"
          idioma="Português Harmonense">
        </InfoboxNation>
    </div>
  );
}

export default NovaHarmonia;