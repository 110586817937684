import '../page.css';

import InfoboxBattle from '../../components/InfoBox/InfoboxBattle';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';
import MainArticle from '../../components/ArticleTitles/MainArticle';
import ArticleRedirect from '../../components/ArticleTitles/ArticleRedirect';

import { images } from '../../components/ImagesSrc';
import barragemArtilhariaHarmonense from '../../assets/img/maps/barragem nova harmonia.png'

function IntervencaoEmGuararapes() {
    const beligerantesLado1 = [
        { nome: "Minuano", flagSrc: images.minuanoFlag, to: "/minuano" },
        { nome: "Montes Claros", flagSrc: images.montesclarosFlag, to: "/montesclaros" },
        { nome: "Vasterras", flagSrc: images.vasterrasFlag, to: "/vasterras" },
        { nome: "Baependy", flagSrc: images.baependyFlag, to: "/baependy"},
        { nome: "Nova Harmonia", flagSrc: images.novaharmoniaFlag, to:"/novaharmonia"},
        { nome: "Potiguaro", flagSrc: images.potiguaroFlag, to: "/potiguaro"},
        { nome: "Nova Carapicuíba", flagSrc: images.novacarapicuibaFlag, to: "/novacarapicuiba"},
        { nome: "Vazios Polares", flagSrc: images.vaziospolaresFlag, to: "/vaziospolares"}
    ];

    const beligerantesLado2 = [
        { nome: "Guararapes", flagSrc: images.guararapesFlag, to: "/guararapes" }
    ];

    const comandantesLado1 = [
        { nome: "Alberto de Córdoba e Luna I", flagSrc: images.minuanoFlag, to: "/minuano" },
        { nome: "Presidente Não Sei", flagSrc: images.montesclarosFlag, to: "/montesclaros" },
        { nome: "Estevão III", flagSrc: images.vasterrasFlag, to: "/vasterras" },
    ];

    const comandantesLado2 = [
        
        { nome: "Leonardo I", flagSrc: images.guararapesFlag, to: "/guararapes" }
    ];

    const forcasLado1 = [
        { quantidade: "30.000 Soldados", flagSrc: images.minuanoFlag, to: "/minuano" },
        { quantidade: "10.000 Soldados", flagSrc: images.montesclarosFlag, to: "/montesclaros" }
    ];

    const forcasLado2 = [
        { quantidade: "100.000 Soldados", flagSrc: images.guararapesFlag, to: "/guararapes" }
    ];
    return (
        <div>

            <PageTitle title="Intervenção em Guararapes" />
            <InfoboxBattle
            title="Intervenção em Guararapes"
            imageSrc={images.barragemArtilhariaHarmonense}
            leg="Mapa da Barragem de Artilharia Harmonense"
            data="551"
            localizacao="Guararapes"
            resultado="Vitória decisiva da Coalizão, ocupação total de Guararapes"
            beligerantesLado1={beligerantesLado1}
            beligerantesLado2={beligerantesLado2}
            comandantesLado1={comandantesLado1}
            comandantesLado2={comandantesLado2}
            forcasLado1={forcasLado1}
            forcasLado2={forcasLado2}
            baixasLado1="10.000 baixas"
            baixasLado2="50.000 baixas"
        />

            <div className='PageContent'>
                A Intervenção em Guararapes ocorreu em 551, com uma coalizão composta pelos membros do Conselho dos Mares e
                outras nações insatisfeitas com a nação guararapenha. A intervenção foi liderada pela Confederação Imperial
                de Minuano após a Defenestração de Magnópolis, causada por delegados da Confederação dos Guararapes. O conflito
                viu três teatros principais, sendo o Fronte Harmonense, o Fronte Costeiro e o Fronte do Interior.

                <br/><br/>

                A barragem de artilharia harmonense seguida pela invasão terrestre foi o primeiro confronto entre as forças armadas
                da coalizão e o exército guararapenho.

                <PageTitle level={3} title="Operação Padaria Queimada"/>

                <MainArticle text="Ver artigo principal:" to="../operacao_pararia_queimada" linkText="Operação Padaria Queimada" />
                <br/>
                Na madrugada do dia 1 de Setembro, uma força-tarefa naval composta por Minuano, Montes Claros e Vasterras bombardeou
                a capital guararapenha e logo em seguida o 17º Corpo de Fuzileiros Navais de Minuano desembarcou na cidade, que foi
                abandonada pelo governo guararapenho. Nas proximidades, uma divisão de blindados de Montes Claros desembarcou e cercou
                as forças costeiras de guararapes enquanto um grupo de paraquedistas de Vasterras ocupava as rotas de recuada da cidade,
                efetivamente cercando toda a guarnição da capital. 
                
            </div>

        </div>
    );
}

export default IntervencaoEmGuararapes;
