import React from "react";
import './MapComponent.css';
import { images } from '../../components/ImagesSrc';

function Tooltip({ position, nome }) {
    return (
        <div
            className="Tooltip"
            style={{
                position: 'absolute',
                top: `${position.y}px`,
                left: `${position.x}px`,
                transform: 'translate(10px, 10px)',
            }}
        >
            <span className="TooltipSpan">{nome}</span>
            <img className="TooltipFlag" src={images.aeternaFlag} alt="Flag" />
        </div>
    );
}

export default Tooltip;
